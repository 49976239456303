import React, { useEffect, useMemo, useState } from "react";

import { MarketingPerformance } from "./MarketingPerformance";
import MarketingChannelOverviewTable from "./MarketingChannelOverviewTable";
import dayjs from "dayjs";

import useSelectedStores from "@lib/hooks/use-selected-stores";
import { transformToChannelScore } from "@api/services/analytics/analytics";
import {
  MarketingChannelOverviewInterface,
  MarketingOverviewInterface,
} from "interface/MarketingChannelOverviewInterface";
import { AnalyticsResultType, useAnalytics } from "@lib/api-hooks/useAnalytics";
import { DatePageHeader } from "@components/Layout/PageHeader/DatePageHeader";
import { EventsResult } from "@lib/api-hooks";
import { useEvents } from "@lib/api-hooks";
import { useAtom } from "jotai";
import {
  NvrType,
  endTimeAtom,
  newVsReturningAtom,
  posthogFeatureFlagsAtom,
  startTimeAtom,
} from "atoms";
import {
  BaseAnalytics,
  BaseOrder,
  NvrBaseAnalytics,
} from "@api/types/backendTypes";
import { StorePerformanceContainer } from "./StorePerformanceContainer";
import NvrDropdown from "@components/NvrDropdown/NvrDropdown";
import {
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
  emptyBaseAnalytics,
  marketingOverviewSettings,
} from "constants/constants";
import { useInitialTimeRange } from "@lib/hooks/use-initial-timerange";
import { useLoadingState } from "@lib/hooks/use-loading-state";
import {
  empty_aggregated_data,
  empty_daily_data,
  empty_events_data,
} from "constants/empty-dashboard-data";
import PageLoadingButton from "@components/PageLoadingButton/PageLoadingButton";
import { DailyChannelData } from "./StorePerformanceChart";
import { CardContainer } from "@components/Cards/CardContainer";
import DecifyIntegrationPlaceholder from "./DecifyIntegrationPlaceholder";
import { PpsDataContainer } from "./PpsDataContainer";
import { usePpsData } from "@lib/api-hooks/usePpsData/usePpsData";

export const getHistoricalSpendData = (dailyChannelData: {
  new: DailyChannelData;
  returning: DailyChannelData;
  all: DailyChannelData;
}) => {
  const allData: Record<
    string,
    Array<{ date: string; spend: number; provider: string; channel: string }>
  > = {};
  const newData: Record<
    string,
    Array<{ date: string; spend: number; provider: string; channel: string }>
  > = {};
  const returningData: Record<
    string,
    Array<{ date: string; spend: number; provider: string; channel: string }>
  > = {};

  if (dailyChannelData?.all) {
    for (const [date, dailyData] of Object.entries(dailyChannelData.all)) {
      if (Array.isArray(dailyData)) {
        for (const dailyChannelData of dailyData) {
          if (!allData[dailyChannelData.provider]) {
            allData[dailyChannelData.provider] = [];
          }
          allData[dailyChannelData.provider].push({
            date: date,
            spend: dailyChannelData.spend,
            provider: dailyChannelData.provider,
            channel: dailyChannelData.channel,
          });
        }
      } else {
        if (!allData[dailyData.provider]) {
          allData[dailyData.provider] = [];
        }
        allData[dailyData.provider].push({
          date: date,
          spend: dailyData.spend,
          provider: dailyData.provider,
          channel: dailyData.channel,
        });
      }
    }
  }
  if (dailyChannelData?.new) {
    for (const [date, dailyData] of Object.entries(dailyChannelData.new)) {
      if (Array.isArray(dailyData)) {
        for (const dailyChannelData of dailyData) {
          if (!newData[dailyChannelData.provider]) {
            newData[dailyChannelData.provider] = [];
          }
          newData[dailyChannelData.provider].push({
            date: date,
            spend: dailyChannelData.spend,
            provider: dailyChannelData.provider,
            channel: dailyChannelData.channel,
          });
        }
      } else {
        if (!newData[dailyData.provider]) {
          newData[dailyData.provider] = [];
        }
        newData[dailyData.provider].push({
          date: date,
          spend: dailyData.spend,
          provider: dailyData.provider,
          channel: dailyData.channel,
        });
      }
    }
  }
  if (dailyChannelData?.returning) {
    for (const [date, dailyData] of Object.entries(
      dailyChannelData.returning
    )) {
      if (Array.isArray(dailyData)) {
        for (const dailyChannelData of dailyData) {
          if (!returningData[dailyChannelData.provider]) {
            returningData[dailyChannelData.provider] = [];
          }
          returningData[dailyChannelData.provider].push({
            date: date,
            spend: dailyChannelData.spend,
            provider: dailyChannelData.provider,
            channel: dailyChannelData.channel,
          });
        }
      } else {
        if (!returningData[dailyData.provider]) {
          returningData[dailyData.provider] = [];
        }
        returningData[dailyData.provider].push({
          date: date,
          spend: dailyData.spend,
          provider: dailyData.provider,
          channel: dailyData.channel,
        });
      }
    }
  }

  return { allData, newData, returningData };
};

const getAdsData = ({
  data,
  newVsReturning,
  ppsEnabled,
}: {
  data: AnalyticsResultType;
  newVsReturning: NvrType;
  ppsEnabled: boolean;
}) => {
  const defaultView = ppsEnabled ? "tc_oid_aggregated" : "tc_aggregated";
  const nvrView = ppsEnabled ? "nvr_tc_oid_aggregated" : "nvr_tc_aggregated";
  return newVsReturning === "default" ? data?.[defaultView] : data?.[nvrView];
};

const getDailyOrderData = ({
  data,
  newVsReturning,
}: {
  data: AnalyticsResultType;
  newVsReturning: NvrType;
}) => {
  const dailyOrderData: Record<string, Map<string, BaseOrder>> = {};
  if (newVsReturning === "default" && data?.tc_oid_daily) {
    for (const [date, dailyData] of Object.entries(data?.tc_oid_daily)) {
      dailyOrderData[date] = dailyData.orders;
    }
  } else if (newVsReturning !== "default" && data?.nvr_tc_oid_daily) {
    for (const [date, dailyData] of Object.entries(data?.nvr_tc_oid_daily)) {
      dailyOrderData[date] = dailyData[newVsReturning]?.orders ?? new Map();
    }
  }
  return dailyOrderData;
};

const getDailyChannelData = ({
  data,
  hasData,
  ppsEnabled,
}: {
  data: AnalyticsResultType;
  hasData: boolean;
  ppsEnabled: boolean;
}) => {
  const newData: { [date: string]: MarketingChannelOverviewInterface[] } = {};
  const returningData: {
    [date: string]: MarketingChannelOverviewInterface[];
  } = {};
  const allData: { [date: string]: MarketingChannelOverviewInterface[] } = {};
  const defaultView = ppsEnabled ? "tc_oid_daily" : "tc_daily";
  const nvrView = ppsEnabled ? "nvr_tc_oid_daily" : "nvr_tc_daily";
  if (!hasData) {
    for (const [date, dailyData] of Object.entries(empty_daily_data())) {
      const allAnalyticsChannelData = transformToChannelScore(
        dailyData ?? (emptyBaseAnalytics as BaseAnalytics)
      );
      if (allAnalyticsChannelData) {
        allData[date] = [...allAnalyticsChannelData];
        newData[date] = [...allAnalyticsChannelData];
        returningData[date] = [...allAnalyticsChannelData];
      }
    }
  } else if (
    data?.[defaultView] &&
    Object.keys(data?.[defaultView] ?? {})?.length > 0
  ) {
    for (const [date, dailyData] of Object.entries(data?.[defaultView] ?? {})) {
      const allAnalyticsChannelData = transformToChannelScore(
        dailyData ?? (emptyBaseAnalytics as BaseAnalytics)
      );
      if (allAnalyticsChannelData) {
        allData[date] = [...allAnalyticsChannelData];
      }
    }
  } else if (
    data?.[nvrView] &&
    Object.keys(data?.[nvrView] ?? {})?.length > 0
  ) {
    for (const [date, dailyData] of Object.entries(data?.[nvrView] ?? {})) {
      const allAnalyticsChannelData = transformToChannelScore(
        dailyData.all ?? (emptyBaseAnalytics as BaseAnalytics),
        { calculateNewCustomerScores: true, isNewUserData: false }
      );
      const newAnalyticsChannelData = transformToChannelScore(
        dailyData.new ?? (emptyBaseAnalytics as BaseAnalytics),
        { calculateNewCustomerScores: true, isNewUserData: true }
      );
      const returningAnalyticsChannelData = transformToChannelScore(
        dailyData.returning ?? (emptyBaseAnalytics as BaseAnalytics)
      );

      if (allAnalyticsChannelData) {
        allData[date] = [...allAnalyticsChannelData];
      }
      if (newAnalyticsChannelData) {
        newData[date] = [...newAnalyticsChannelData];
      }
      if (returningAnalyticsChannelData) {
        returningData[date] = [...returningAnalyticsChannelData];
      }
    }
  }
  return {
    new: newData,
    returning: returningData,
    all: allData,
  };
};

const getChannelData = ({
  adsData,
  hasData,
  newVsReturning,
  historicalSpendData,
}: {
  adsData?: BaseAnalytics | NvrBaseAnalytics;
  hasData: boolean;
  newVsReturning: NvrType;
  historicalSpendData?: ReturnType<typeof getHistoricalSpendData>;
}) => {
  const data = {
    new: [] as MarketingChannelOverviewInterface[],
    all: [] as MarketingChannelOverviewInterface[],
    returning: [] as MarketingChannelOverviewInterface[],
  };
  if (!hasData) {
    const allAnalyticsChannelData = transformToChannelScore(
      empty_aggregated_data() as BaseAnalytics
    );
    return {
      new: allAnalyticsChannelData,
      all: allAnalyticsChannelData,
      returning: allAnalyticsChannelData,
    };
  } else if (adsData && Object.keys(adsData).length > 0) {
    if (newVsReturning === "default") {
      const allAnalyticsChannelData = transformToChannelScore(
        (adsData as BaseAnalytics) ?? (emptyBaseAnalytics as BaseAnalytics)
      );

      for (const data of allAnalyticsChannelData) {
        const historicalData = historicalSpendData?.allData?.[data.provider];
        if (historicalData) {
          data.historical = historicalData;
        }
      }

      return {
        new: [] as MarketingChannelOverviewInterface[],
        all: allAnalyticsChannelData,
        returning: [] as MarketingChannelOverviewInterface[],
      };
    } else {
      const allAnalyticsChannelData = transformToChannelScore(
        (adsData as NvrBaseAnalytics).all ?? ({} as NvrBaseAnalytics),
        { calculateNewCustomerScores: true }
      );
      for (const data of allAnalyticsChannelData) {
        const historicalData = historicalSpendData?.allData?.[data.provider];
        if (historicalData) {
          data.historical = historicalData;
        }
      }
      const newAnalyticsChannelData = transformToChannelScore(
        (adsData as NvrBaseAnalytics).new ?? ({} as NvrBaseAnalytics),
        { calculateNewCustomerScores: true, isNewUserData: true }
      );
      for (const data of newAnalyticsChannelData) {
        const historicalData = historicalSpendData?.newData?.[data.provider];
        if (historicalData) {
          data.historical = historicalData;
        }
      }
      const returningAnalyticsChannelData = transformToChannelScore(
        (adsData as NvrBaseAnalytics).returning ?? ({} as NvrBaseAnalytics)
      );
      for (const data of returningAnalyticsChannelData) {
        const historicalData =
          historicalSpendData?.returningData?.[data.provider];
        if (historicalData) {
          data.historical = historicalData;
        }
      }
      return {
        new: newAnalyticsChannelData,
        returning: returningAnalyticsChannelData,
        all: allAnalyticsChannelData,
      };
    }
  }
  return data;
};

const getOverviewData = ({
  channelData,
  eventsData,
  newVsReturning,
}: {
  channelData: {
    new: MarketingChannelOverviewInterface[];
    all: MarketingChannelOverviewInterface[];
    returning: MarketingChannelOverviewInterface[];
  };
  eventsData: EventsResult;
  newVsReturning: NvrType;
}) => {
  const newData: MarketingOverviewInterface = {
    spend: 0,
    cpo: 0,
    purchaseCount: 0,
    purchaseAmount: 0,
    roas: 0,
    cac: 0,
    ncr: 0,
  };
  if (channelData && eventsData) {
    const totalOrders = eventsData.totals?.total_count;
    let trcTrackedOrders = 0;
    let trcNewCustomers = 0;
    for (const channel of channelData.all) {
      trcTrackedOrders += channel.purchaseCount;
    }
    for (const channel of channelData.new) {
      trcNewCustomers += channel.purchaseCount;
    }

    for (const channel of channelData[
      newVsReturning === "default" ? "all" : newVsReturning
    ]) {
      newData.spend += channel.spend;
      newData.purchaseCount += channel.purchaseCount;
      newData.purchaseAmount += channel.purchaseAmount;
    }
    newData.roas =
      newData.spend > 0 ? newData.purchaseAmount / newData.spend : 0;
    newData.cpo =
      newData.purchaseCount > 0 ? newData.spend / newData.purchaseCount : 0;
    if (trcNewCustomers > 0 && trcTrackedOrders) {
      newData.ncr = trcNewCustomers / trcTrackedOrders;
    }
    if (trcTrackedOrders > 0 && totalOrders > 0 && trcNewCustomers > 0) {
      newData.cac =
        ((trcTrackedOrders / totalOrders) * newData.spend) / trcNewCustomers;
    }
  }
  return newData;
};

export function MarketingOverviewDashboard() {
  const { isPending, selectedStoreIds } = useSelectedStores();
  const [posthogFeatureFlags] = useAtom(posthogFeatureFlagsAtom);
  const ppsEnabled = posthogFeatureFlags?.pps_modules?.pps_modules;
  const initialTimeRange = useMemo(
    () => ({
      startDate: dayjs().subtract(7, "days").startOf("day").toDate(),
      endDate: dayjs().subtract(1, "days").endOf("day").toDate(),
      timerange:
        "last_7_days" as (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"],
    }),
    []
  );
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const maxDate = useMemo(
    () =>
      dayjs()
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .toDate(),
    []
  );
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const { initialTimeRangeIsSet } = useInitialTimeRange({
    maxDate,
    initialTimeRange,
  });
  const {
    progress,
    finished,
    isFetching: fetchingData,
    data,
    showNvrTooltip,
    refetch: onFetchData,
    hasData: hasAnalyticsData,
  } = useAnalytics(
    {
      ...marketingOverviewSettings(
        Boolean(newVsReturning !== "default"),
        Boolean(ppsEnabled)
      ),
    },
    {
      enabled: initialTimeRangeIsSet && !isPending,
    }
  );

  const { data: ppsData, isPending: isPendingPpsData } = usePpsData({
    csids: selectedStoreIds,
    created_at_min: startTime,
    created_at_max: endTime,
    enabled: Boolean(ppsEnabled),
  });
  const {
    data: eventsDataResult,
    refetch: onFetchEvents,
    isFetching: fetchingEvents,
  } = useEvents(
    { dailyBreakdown: true },
    {
      enabled: initialTimeRangeIsSet && !isPending,
    }
  );
  const { showLoadingState, isLoading } = useLoadingState({
    finished,
    fetchingData: fetchingData || fetchingEvents,
  });

  const hasData = useMemo(() => {
    if (showLoadingState || isPending) {
      return true;
    }
    return (
      hasAnalyticsData &&
      (Boolean(eventsDataResult?.totals?.total_amount) ||
        Boolean(eventsDataResult?.totals?.total_count))
    );
  }, [
    eventsDataResult?.totals?.total_amount,
    eventsDataResult?.totals?.total_count,
    hasAnalyticsData,
    isPending,
    showLoadingState,
  ]);
  const [eventsData, setEventsData] = useState(
    hasData ? eventsDataResult : empty_events_data()
  );

  const [adsData, setAdsData] = useState(
    hasData
      ? getAdsData({ data, newVsReturning, ppsEnabled })
      : emptyBaseAnalytics
  );

  const [comparedAdsData, setComparedAdsData] = useState(
    hasData && data?.compared
      ? getAdsData({ data: data?.compared, newVsReturning, ppsEnabled })
      : null
  );

  const [comparedEventsData, setComparedEventsData] = useState(
    hasData && eventsDataResult?.compared ? eventsDataResult.compared : null
  );

  const [dailyChannelData, setDailyChannelData] = useState(
    getDailyChannelData({ data, hasData, ppsEnabled })
  );

  const [comparedDailyChannelData, setComparedDailyChannelData] = useState(
    getDailyChannelData({ data: data?.compared, hasData, ppsEnabled })
  );
  const [historicalSpendData, setHistoricalSpendData] = useState(
    getHistoricalSpendData(dailyChannelData)
  );
  const [comparedHistoricalSpendData, setComparedHistoricalSpendData] =
    useState(getHistoricalSpendData(comparedDailyChannelData));

  const [channelData, setChannelData] = useState(
    getChannelData({
      adsData,
      hasData,
      newVsReturning,
      historicalSpendData,
    })
  );
  const [dailyOrderData, setDailyOrderData] = useState(
    getDailyOrderData({
      data,
      newVsReturning,
    })
  );
  const [comparedChannelData, setComparedChannelData] = useState(
    comparedAdsData
      ? getChannelData({
          adsData: comparedAdsData,
          hasData,
          newVsReturning,
          historicalSpendData: comparedHistoricalSpendData,
        })
      : null
  );

  const [overviewData, setOverviewData] = useState(
    getOverviewData({ channelData, eventsData, newVsReturning })
  );
  const [comparedOverviewData, setComparedOverviewData] = useState(
    comparedChannelData && comparedEventsData
      ? getOverviewData({
          channelData: comparedChannelData,
          eventsData: comparedEventsData,
          newVsReturning,
        })
      : null
  );
  useEffect(() => {
    if (!fetchingData && !fetchingEvents) {
      const newAdsData = hasData
        ? getAdsData({ data, newVsReturning, ppsEnabled })
        : emptyBaseAnalytics;
      const newComparedAdsData =
        hasData && data?.compared
          ? getAdsData({ data: data?.compared, newVsReturning, ppsEnabled })
          : null;
      setDailyOrderData(getDailyOrderData({ data, newVsReturning }));
      setAdsData(newAdsData);
      const newEventsData = hasData ? eventsDataResult : empty_events_data();
      setEventsData(newEventsData);
      setComparedAdsData(newComparedAdsData);
      const newComparedEventsData =
        hasData && eventsDataResult?.compared
          ? eventsDataResult.compared
          : null;
      setComparedEventsData(newComparedEventsData);

      const newDailyChannelData = getDailyChannelData({
        data,
        hasData,
        ppsEnabled,
      });
      const newComparedDailyChannelData = getDailyChannelData({
        data: data?.compared,
        hasData,
        ppsEnabled,
      });
      setDailyChannelData(newDailyChannelData);
      setComparedDailyChannelData(newComparedDailyChannelData);

      const newHistoricalSpendData =
        getHistoricalSpendData(newDailyChannelData);
      const newComparedHistoricalSpendData = getHistoricalSpendData(
        newComparedDailyChannelData
      );
      setHistoricalSpendData(newHistoricalSpendData);
      setComparedHistoricalSpendData(newComparedHistoricalSpendData);

      const newChannelData = getChannelData({
        adsData: newAdsData,
        hasData,
        newVsReturning,
        historicalSpendData: newHistoricalSpendData,
      });
      const newComparedChannelData = newComparedAdsData
        ? getChannelData({
            adsData: newComparedAdsData,
            hasData,
            newVsReturning,
            historicalSpendData: newComparedHistoricalSpendData,
          })
        : null;
      setChannelData(newChannelData);
      setComparedChannelData(newComparedChannelData);

      const newOverviewData = getOverviewData({
        channelData: newChannelData,
        eventsData: newEventsData,
        newVsReturning,
      });
      const newComparedOverviewData =
        newComparedChannelData && newComparedEventsData
          ? getOverviewData({
              channelData: newComparedChannelData,
              eventsData: newComparedEventsData,
              newVsReturning,
            })
          : null;
      setOverviewData(newOverviewData);
      setComparedOverviewData(newComparedOverviewData);
    }
  }, [
    data,
    eventsDataResult,
    fetchingData,
    fetchingEvents,
    hasData,
    newVsReturning,
    ppsEnabled,
  ]);

  const trackedOrdersRatio = useMemo(() => {
    const orderRatio: { [date: string]: number } = {};
    if (eventsData && dailyChannelData?.all) {
      // eslint-disable-next-line guard-for-in
      for (const [date, result] of Object.entries(eventsData)) {
        const totalOrders = result?.total_count;
        let trcTrackedOrders = 0;
        const currentData = dailyChannelData.all[date];
        if (currentData) {
          currentData.forEach((el) => (trcTrackedOrders += el.purchaseCount));
        }
        if (totalOrders > 0 && trcTrackedOrders > 0) {
          orderRatio[date] = trcTrackedOrders / totalOrders;
        }
      }
    }
    return orderRatio;
  }, [dailyChannelData.all, eventsData]);
  return (
    <div className="relative">
      <div className="sticky top-[62px] lg:top-0 bg-background py-2  z-header">
        <DatePageHeader
          title="Marketing Overview"
          maxDate={maxDate}
          enableCompare
        >
          <div className="flex space-x-2 items-center mr-2">
            <PageLoadingButton
              fetchingData={isPending || (fetchingData && !finished)}
              isPending={isPending}
              onFetchData={() => {
                onFetchData();
                onFetchEvents();
              }}
              progress={progress}
              showNvrTooltip={showNvrTooltip}
            />
            <NvrDropdown />
          </div>
        </DatePageHeader>
      </div>
      <div className="relative">
        {hasData || showLoadingState ? null : (
          <div className="bg-background/10 backdrop-blur-sm absolute inset-0 z-40 rounded-md -m-2 text-center py-16">
            <div className="sticky top-[350px] inset-x-0 text-foreground">
              <p className="font-semibold text-lg">No Data Available</p>
              <p className="mt-4">
                There is no data available for the selected timeframe. Please
                choose a different one.
              </p>
            </div>
          </div>
        )}
        <div className="mt-2 mb-8">
          <MarketingPerformance
            data={{ ...overviewData, compared: comparedOverviewData }}
            loading={showLoadingState || isPending}
            dailyChannelData={{
              ...dailyChannelData,
              compared: comparedDailyChannelData,
            }}
            storeEventsData={
              eventsData
                ? { ...eventsData, compared: comparedEventsData }
                : ({} as EventsResult)
            }
            trackedOrdersRatio={trackedOrdersRatio}
          />
        </div>
        <div className="mb-8">
          <MarketingChannelOverviewTable
            data={
              channelData[newVsReturning === "default" ? "all" : newVsReturning]
            }
            comparedData={
              comparedChannelData
                ? comparedChannelData[
                    newVsReturning === "default" ? "all" : newVsReturning
                  ]
                : undefined
            }
            loading={showLoadingState || isPending}
            progress={progress}
          />
        </div>
        <div className="lg:mb-8 mb-0">
          <StorePerformanceContainer
            dailyChannelData={{
              ...dailyChannelData,
              compared: comparedDailyChannelData,
            }}
            loading={showLoadingState || isPending}
            trackedOrdersRatio={trackedOrdersRatio}
          />
        </div>
        {ppsEnabled ? (
          <PpsDataContainer
            dailyOrderData={dailyOrderData}
            dailyChannelData={
              dailyChannelData[
                newVsReturning === "default" ? "all" : newVsReturning
              ]
            }
            ppsData={ppsData}
            loading={showLoadingState || isPending || isPendingPpsData}
          />
        ) : null}
      </div>
    </div>
  );
}
